.card {
    width: 100%;
    margin: 5px;

}

.card-body>.card-button {
    background-color: #8c52ff;
    color: white;
}

.card-body>.card-button:hover {
    background-color: #b152ff;
    color: white;
}

.card-body>.card-title {
    font-family: poppins;
    font-weight: 600;
    /* color: white; */
}

.card-text {
    font-family: poppins;

}

@media screen and (max-width: 680px) {
    .card {
        width: 100%;
    }

    .card-title {

        font-size: 14px;
    }
}