div h2{
    font-size: 22px;
    font-weight: bold;
}
div h3{
    font-size: 20px;
    font-weight: bold;
}
div h4{
    font-size: 20px;
    font-weight: bold;
}
div h5{
    font-size: 20px;
    font-weight: bold;
}