.thumbnail img{
    width: 60%;
    border-radius: 10px;
}

@media only screen and (max-width: 600px) {
    .thumbnail img{
        width: 100%;
        border-radius: 10px;
    }
  }