.logoImage {
    width: 100%;
    height: 100px;
}

.logorow {
    /* margin-top: 350px; */
    padding-top: 80px;
    height: 100vh;
    /* background-color: blueviolet; */
}

.mylogo {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}