.contact{
    padding: 4%;
    height: 400px;
}
.my-column{
    background: #ff9b00;
    padding: 4%;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}
.contact-info{
    margin-top:10%;
}
.contact-info img{
    margin-bottom: 15%;
}
.contact-info h2{
    margin-bottom: 10%;
}
.col-md-9{
    background: #fff;
    padding: 3%;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
.contact-form label{
    font-weight:600;
}
.contact-form input{
    border-radius: 10px;
    height: 50px;
    margin-bottom: 20px;
    
}
.contact-form button{
    background: #25274d;
    color: #fff;
    font-weight: 600;
    width: 25%;
}
.contact-form button:focus{
    box-shadow:none;
}
.card-body iframe{
    width: 100%;
    height: 400px;
}